<template>
    <div class="popup" :class="classObj" @click="showTypeList = false">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="header">
                <div>{{ $t("Pending.Pending") }}</div>
                <div class="close">
                    <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                </div>
            </div>
            <div class="content">
                <div class="type_box">
                    <div class="nowType" @click.stop="showTypeList = !showTypeList">
                        <div>{{ eventTypeList[typeAct].showName }}</div>
                        <i class="iconfont icon-open" :class="{ act: showTypeList }"></i>
                    </div>
                    <div class="types" :class="{ act: showTypeList }">
                        <div class="type" v-for="(item, index) in eventTypeList" :key="index"
                            :class="{ act: index === typeAct }" @click.stop="changeType(index, item.eventType)">
                            {{ item.showName }}
                        </div>
                    </div>
                </div>
                <div class="list_content">
                    <div class="top">
                        <div>{{ $t("Pending.Hour") }}</div>
                        <div>{{ $t("Pending.Type") }}</div>
                        <div>{{ $t("Pending.Bonus") }}</div>
                        <div>{{ $t("Pending.Limit") }}</div>
                        <div>{{ $t("Pending.EndTime") }}</div>
                    </div>
                    <van-list v-model="isLoading" :finished="hasMore" loading-text="" @load="getPendingList"
                        class="list" offset="50" :immediate-check=false v-if="list.length !== 0">
                        <div class="list_item" v-for="item in list" :key="item.id">
                            <div>{{ item.matterTime }}</div>
                            <div>
                                {{ item.showName }}
                            </div>
                            <div>{{ item.amount }}</div>
                            <div>{{ item.eventWaterAmtNum }}</div>
                            <div>{{ item.endTime }}</div>
                            <div class="btn" @click="onObter(item.id)">go to</div>
                        </div>
                    </van-list>
                    <div class="empty" v-else>
                        <img src="@/assets/common/empty.png" alt="" class="empty_img">
                        <div>{{ $t("lang.NoData") }}</div>
                    </div>
                    <div v-if="isLoading && list.length" class="loading">
                        <a-spin>
                            <a-icon slot="indicator" type="loading" spin />
                        </a-spin>
                        {{ $t("lang.Loading") }}
                    </div>
                    <div v-else-if="!hasMore && !isLoading && list.length" class="loading">
                        {{ $t("lang.NoMore") }}
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import {
    getClaimableInfo,
    getAward
} from '@/api/pending'
import { mapState } from 'vuex'
export default {
    name: 'pendingPop',
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        show: {
            get() {
                return this.$store.getters.isShowPendingPopup
            },
            set(val) {
                return val
            }
        }
    },
    data() {
        return {
            list: [],
            allList: [],
            pageNo: 0,
            hasMore: true,
            isLoading: false,
            typeAct: 0,
            showTypeList: false,
            firstLoaded: true,
            eventTypeList: [
                {
                    id: 0,
                    eventType: 'all',
                    showName: this.$t('Pending.All')
                },
                {
                    id: 1,
                    eventType: 'signIn',
                    showName: this.$t('Pending.DailyAttendance')
                },
                {
                    id: 2,
                    eventType: 'redPacket',
                    showName: this.$t('Pending.TimedRedEnvelope')
                },
                {
                    id: 3,
                    eventType: 'luckyWheel',
                    showName: this.$t('Pending.LuckyTurntable')
                },
                {
                    id: 4,
                    eventType: 'reliefMoney',
                    showName: this.$t('Pending.ReliefMoney')
                },
                {
                    id: 5,
                    eventType: 'registerRewards',
                    showName: this.$t('Pending.RegisterRewards')
                },
                {
                    id: 6,
                    eventType: 'other',
                    showName: this.$t('Pending.Other')
                }
            ]
        }
    },
    created() { },
    methods: {
        open() {
            this.eventTypeList = [
                {
                    id: 0,
                    eventType: 'all',
                    showName: this.$t('Pending.All')
                },
                {
                    id: 1,
                    eventType: 'signIn',
                    showName: this.$t('Pending.DailyAttendance')
                },
                {
                    id: 2,
                    eventType: 'redPacket',
                    showName: this.$t('Pending.TimedRedEnvelope')
                },
                {
                    id: 3,
                    eventType: 'luckyWheel',
                    showName: this.$t('Pending.LuckyTurntable')
                },
                {
                    id: 4,
                    eventType: 'reliefMoney',
                    showName: this.$t('Pending.ReliefMoney')
                },
                {
                    id: 5,
                    eventType: 'registerRewards',
                    showName: this.$t('Pending.RegisterRewards')
                },
                {
                    id: 6,
                    eventType: 'other',
                    showName: this.$t('Pending.Other')
                }
            ]
            if (this.firstLoaded) {
                this.getPendingList()
            }
        },
        close() {
            this.$store.dispatch('user/setIsShowPendingPopup', false)
        },
        changeType(index, type) {
            if (index === this.typeAct) return
            this.typeAct = index
            this.showTypeList = false
            this.getNowList(type)
        },
        getPendingList() {
            if (!this.hasMore) return
            this.isLoading = true
            this.pageNo++
            const params = {
                pageNo: this.pageNo,
                pageSize: 20
            }
            getClaimableInfo(params).then((res) => {
                if (res.code) return
                const D = res.result
                this.allList = this.allList.concat(D)
                this.hasMore = D.total > this.allList.length
                this.allList.forEach(record => {
                    const a = this.eventTypeList.find(event => event.eventType === record.eventType)
                    if (a) {
                        record.showName = a.showName
                    } else {
                        record.showName = this.eventTypeList[this.eventTypeList.length - 1].showName
                    }
                })
                this.getNowList(this.eventTypeList[this.typeAct].eventType)
                // this.firstLoaded=false
                this.isLoading = false
            })
        },
        getNowList(eventType) {
            if (eventType === 'all') {
                this.list = this.allList
            } else if (eventType === 'other') {
                this.list = this.allList.filter(record => record.eventType !== eventType)
            } else {
                this.list = this.allList.filter(record => record.eventType === eventType)
            }
            // console.log('111', this.list)
        },
        onObter(id) {
            const that = this
            Dialog.alert({
                title: 'Tips',
                message: 'Should I receive it?',
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                showCancelButton: true,
                confirmButtonColor: '#000000',
                closeOnClickOverlay: true
            }).then(() => {
                getAward({
                    id
                }).then((res) => {
                    if (res.code) return
                    Toast(res.message)
                    that.onRefresh()
                })
            }).catch(() => {
            })
        },
        onRefresh() {
            this.pageNo = 0
            this.allList = []
            this.list = []
            this.hasMore = true
            this.isLoading = false
            this.getPendingList()
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    text-align: left;
    position: relative;

    &.mobile {
        .van-popup {
            &.van-popup--center {
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                height: 100% !important;
                background: #15161c;
                display: flex;
                flex-direction: column;
                padding-bottom: 1rem;
            }

            .header {
                width: 100%;
                height: 1.12rem;
                line-height: 1.12rem;
                font-size: .32rem;
                padding: 0 0.3rem;
                color: #fff;
                background: transparent;
                position: static;
                transform: translateX(0);
                text-align: left;
                background: #191b21;
                display: flex;
                justify-content: space-between;

                .close {
                    position: static;
                    color: #516382;
                }
            }

            .content {
                padding: .3rem;

                .type_box {

                    .nowType {
                        font-size: .24rem;
                    }
                }

                .list_content {
                    margin-top: .2rem;
                    // background: #1d2027;

                    .top {
                        font-size: .24rem;
                    }

                    .list {

                        .list_item {
                            font-size: .24rem;
                            height: 1rem;
                            line-height: .26rem;
                        }
                    }

                    .loading {
                        font-size: .24rem;
                        margin-top: .2rem;
                    }

                    .empty {
                        font-size: .32rem;

                        .empty_img {
                            width: 3.4rem;
                        }
                    }
                }
            }
        }
    }

    .van-popup {
        width: 500px;
        height: 700px;
        overflow: visible;
        background: linear-gradient(180deg, #223053, #232d46);
        border-radius: .24rem;

        .header {
            width: 390px;
            height: 56px;
            font-size: 20px;
            color: #fff;
            line-height: 56px;
            background-image: url(@/assets/common/top.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: top left;
            position: absolute;
            left: 50%;
            top: -.23rem;
            transform: translateX(-50%);
            font-weight: bold;
            text-align: center;

            .close {
                position: absolute;
                top: 8px;
                right: -35px;
                color: #99aad2;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all 0.3s;

                i {
                    font-size: 20px;
                }

                &:hover {
                    color: #ffffff;
                    rotate: -180deg;
                }
            }
        }

        .content {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 76px 40px 40px;

            .type_box {
                position: relative;
                width: 50%;

                .nowType {
                    height: .8rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: #2a2f39;
                    border-radius: .24rem;
                    padding: 0 .24rem;
                    width: 100%;
                    color: #ffffff;

                    i {
                        color: #516382;
                        transition: all 0.3s;
                        cursor: pointer;

                        &.act {
                            color: #ffffff;
                            font-weight: bold;
                            transform: rotate(180deg);
                        }
                    }
                }

                .types {
                    position: absolute;
                    z-index: 1;
                    // top: 45px;
                    bottom: -5px;
                    left: 0;
                    width: 100%;
                    background: $bg-box-color;
                    transition: all 0.3s ease-in-out;
                    border-radius: .24rem;
                    overflow: hidden;
                    transform-origin: 0 0;
                    transform: translate3d(0, 100%, 0) scaleY(0);
                    opacity: 0;
                    visibility: hidden;

                    &.act {
                        transform: translate3d(0, 100%, 0) scaleY(1);
                        visibility: visible;
                        opacity: 1;
                    }

                    .type {
                        color: $font-sub-color;
                        height: .8rem;
                        display: flex;
                        align-items: center;
                        // line-height: .8rem;
                        font-size: .24rem;
                        padding: 0 .24rem;
                        cursor: pointer;

                        &.act {
                            font-weight: bold;
                            color: #ffffff;
                            background: #23262f;
                        }
                    }
                }
            }

            .list_content {
                flex: 1;
                margin-top: 15px;
                position: relative;
                border-radius: .24rem;
                background: #191b21;

                .top {
                    display: flex;
                    align-items: center;
                    height: 1.14rem;
                    color: #516382;
                    font-size: 14px;
                    padding-right: 14%;

                    >div {
                        flex: 1;
                        text-align: center;
                    }
                }

                .list {

                    ::v-deep .van-loading {
                        display: none;
                    }

                    .list_item {
                        display: flex;
                        align-items: center;
                        color: $font-sub-color;
                        font-size: 13px;
                        height: 50px;
                        line-height: 14px;

                        &:nth-child(2n+1) {
                            background: #15161c;
                        }

                        >div {
                            width: 18%;
                            // flex: 1;
                            text-align: center;

                            &.btn {
                                margin: 0 2%;
                                width: 10%;
                                padding: .08rem .08rem;
                                background: #286aff;
                                color: #ffffff;
                                border-radius: .24rem;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .loading {
                    color: $font-sub-color;
                    font-size: 12px;
                    text-align: center;
                    margin-top: 10px;
                }

                .empty {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: $font-sub-color;
                    font-size: 16px;
                    text-align: center;

                    .empty_img {
                        width: 170px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
</style>
